import {type IconName, type IconPrefix, library, icon as svgIcon, type Transform} from '@fortawesome/fontawesome-svg-core';
import {fal} from '@fortawesome/pro-light-svg-icons';
import {far} from '@fortawesome/pro-regular-svg-icons';
import {fas} from '@fortawesome/pro-solid-svg-icons';
import {splitFirst} from '@ivosabev/helpers/splitFirst';

// TODO: Import only the necessary icons
library.add(fal, far, fas);

export type PrefixIcon = `${IconPrefix}-${IconName}`;

type Props = {
  icon: PrefixIcon;
  spin?: boolean;
  label?: string;
  className?: string;
  flip?: 'horizontal' | 'vertical' | 'both';
};

export const Icon = (props: Props) => {
  const {className = undefined, flip, icon, label, spin = false} = props;

  const [prefix, iconName] = splitFirst(icon, '-') as [IconPrefix, IconName];
  const classes: string[] = [];
  if (spin) {
    classes.push('fa-spin');
  }

  const transform: Transform = {};
  if (flip === 'both') {
    transform.flipX = true;
    transform.flipY = true;
  } else if (flip === 'horizontal') {
    transform.flipX = true;
  } else if (flip === 'vertical') {
    transform.flipY = true;
  }
  const iconHTML = svgIcon({iconName, prefix}, {classes, transform})?.html;

  if (!iconHTML) {
    console.error(`Icon '${icon}' is missing.`);
    throw new Error(`Icon '${icon}' is missing.`);
  }

  return (
    <span
      aria-label={label}
      className={['leading-none inline-flex justify-center items-center', className].filter(Boolean).join(' ')}
      dangerouslySetInnerHTML={{__html: iconHTML[0] ?? ''}}
    />
  );
};

/* OLD ICON CODE, MIGHT BE NEEDED IF SOME ICONS DON'T WORK AS EXPECTED

import {library, icon as loadIcon} from '@fortawesome/fontawesome-svg-core';
import {fal} from '@fortawesome/pro-light-svg-icons';
import {far} from '@fortawesome/pro-regular-svg-icons';
import {fas} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

// TODO: Import only the necessary icons
library.add(fal, far, fas);

// TODO:
type IconProps =
  | {
      label: string;
      icon: string; // TODO: Accept glyphs and svgs
    }
  | any;

// glyph
// dangerouslySetGlyph
// label
// primaryColor
// secondaryColor
// size = small medium large xxlarge / or number 1,2,3,4,5 ??? in rem based on TailwindCSS ??
// style ?

export const Icon: React.FunctionComponent<IconProps> = (props) => {
  return null;

  // eslint-disable-next-line react/prop-types
  const {icon: name, ...rest} = props;
  const tmpName = name || '';
  const p = tmpName.indexOf('-');
  const prefix = p >= 0 ? tmpName.substr(0, p) : 'far';
  const iconName = p >= 0 ? tmpName.substr(p + 1) : tmpName;

  // @ts-expect-error
  const iconDefinition = (library.definitions[prefix] && library.definitions[prefix][iconName]) || null;
  // @ts-expect-error
  const faIcon = iconDefinition && loadIcon({icon: iconDefinition});
  if (!faIcon) {
    console.error(`Icon '${tmpName}' resolved as '${prefix}-${iconName}' is missing.`);
    return null;
  }

  const faPropsList = ['fixedWidth', 'flip', 'inverse', 'onClick', 'rotation', 'style'];
  const faProps = Object.keys(rest).reduce((p, c) => (faPropsList.indexOf(c) >= 0 ? {...p, [c]: rest[c]} : p), {} as Record<string, any>);
  const containerProps: any = Object.keys(rest).reduce(
    (p, c) => (faPropsList.indexOf(c) === -1 ? {...p, [c]: rest[c]} : p),
    {} as Record<string, any>,
  );

  const cls = {
    container: 'leading-none inline-flex justify-center items-center',
  };

  if (containerProps.className) {
    cls.container += ` ${containerProps.className}`;
  }

  if (containerProps.size) {
    cls.container += ` text-${containerProps.size}`;
  }

  return (
    <span className={cls.container}>
      <FontAwesomeIcon {...faProps} icon={[prefix, iconName]} />
    </span>
  );
};

*/
